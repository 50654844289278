<template>
  <div class="store-keywords-modal-wrap">
    <div class="title">
      Enter Keywords fields data from App Store Console
    </div>
    <div>
      <custom-inline-search placeholder="Search for Countries"
                            @search-input-changed="countrySearchInput = $event"
                            :with-result-slots="true"
                            :min-input-length="0"
                            :show-caret="true"
                            :full-width-content="true"
                            results-max-height="256px"
                            :initial-data="selectedCountry?.code ?? 'All countries'"
                            :close-from-outside-counter="closeDropdownCounter"
                            :config="{
                              border: 'none',
                              borderRadius: '4px',
                              boxShadow: '0px 4px 8px rgba(160, 190, 221, 0.3)',
                              height: '36px',
                              width: '300px',
                            }">

        <template v-slot:heading-info>
          <template v-if="selectedCountry">
            <dynamic-image classes="country-flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="selectedCountry.code"/>
            {{ selectedCountry.name }}
          </template>
          <template v-else>
            All countries
          </template>
        </template>

        <template v-slot:results>
          <div class="results-group">
            <template v-if="countriesListComputed?.length > 0">
              <div class="result-item"
                   @click="clickAllCountries">
                All countries
              </div>
              <div v-for="country in countriesListComputed"
                   @click="countryClickedHandler(country.code, country.name, country.id)"
                   class="result-item">
                <template v-if="country?.code">
                  <dynamic-image classes="country-flag"
                                 :width="18"
                                 :height="14"
                                 :size="32"
                                 :country-code="country?.code"/>
                </template>
                <span class="item-name">{{ country?.name }}</span>
              </div>
            </template>
            <template v-else>
              <div class="results-group">
                <div class="text">No data found</div>
              </div>
            </template>
          </div>
        </template>
      </custom-inline-search>
    </div>

    <preloader v-if="!isDataLoaded" />
    <div v-else class="inputs-wrap">
      <div class="inputs">
        <div v-for="(name, key) in localesForShow"
             class="input-wrap">
          <label :for="'keywordsLocale' + key">
            <span>{{ name }}</span>
            <span class="counter" :class="{full: keywordsData[key]?.length === 100}">
              {{ keywordsData[key]?.length ?? 0 }}/100
            </span>
          </label>
          <div class="form-control">
            <input type="text"
                   maxlength="100"
                   :id="'keywordsLocale' + key"
                   v-model="keywordsData[key]" />
          </div>
        </div>
      </div>

      <div class="button">
        <base-button height="36px"
                     width="140px"
                     class="btn-standard"
                     border-radius="4px"
                     font-size="16px"
                     @click="save">
          Save Meta
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import BaseButton from "@/components/UI/BaseButton/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import Preloader from "@/components/UI/Preloader/index.vue";

export default {
  name: "StoreKeywordsModal",
  components: {Preloader, PreloaderTableSmall, BaseButton, CustomInlineSearch, DynamicImage},
  emits: ['close'],
  props: {
    currentCountry: {
      type: Object,
      required: true,
    },
    countriesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCountry: this.currentCountry,
      countrySearchInput: '',
      countryLocales: {},
      keywordsData: {},
      closeDropdownCounter: 0,
      isDataLoaded: false,
      isSavingInProcess: false,
    }
  },
  mounted() {
    this.fetchLocales();
    this.fetchKeywords();
  },
  methods: {
    async save() {
      this.isSavingInProcess = true;
      let formData = new FormData();
      formData.append('app_id', this.currentApp?.id);
      Object.keys(this.preparedFormKeywords).forEach(key => {
        formData.append(`keywords[${key}]`, this.preparedFormKeywords[key]);
      });

      await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.SAVE_STORE_KEYWORDS,
        formData
      );

      this.$emit('close');
    },
    clickAllCountries() {
      this.selectedCountry = null;
    },
    countryClickedHandler(code, name, id) {
      this.selectedCountry = {
        code: code,
        name: name,
        id: id,
      }
    },
    async fetchLocales() {
      if (!this.selectedCountry?.code) {
        return;
      }

      this.countryLocales = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES + `/${this.currentApp?.store?.key}/${this.selectedCountry.code}/no`
      );
    },
    async fetchKeywords() {
      const result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_STORE_KEYWORDS
          + '?app_id=' + this.currentApp?.id,
      );
      let keywords = {};
      Object.keys(result.keywords).forEach(key => {
        keywords[key] = result.keywords[key].join(',');
      });
      this.keywordsData = {...keywords};
      this.isDataLoaded = true;
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
    countriesListComputed() {
      const loweredInput = this.countrySearchInput?.toLowerCase();
      return this.countriesList.filter(country => country?.name?.toLowerCase()?.includes(loweredInput))
    },
    localesForShow() {
      if (this.selectedCountry) {
        return this.countryLocales.popular;
      }

      return {...this.countryLocales.popular, ...this.countryLocales.other};
    },
    preparedFormKeywords() {
      let preparedKeywords = {};
      Object.keys(this.keywordsData).forEach(key => {
        let keywords = this.keywordsData[key].split(',').map(keyword => keyword.trim())
        keywords = keywords.filter(keyword => keyword.length > 0);
        preparedKeywords[key] = keywords;
      });

      return preparedKeywords;
    },
  },
  watch: {
    selectedCountry() {
      this.closeDropdownCounter++;
      if (this.selectedCountry) {
        this.fetchLocales();
      }
    },
  }
}
</script>

<style lang="scss">
.store-keywords-modal .modal {
  overflow: visible!important;
}

.store-keywords-modal-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .custom-select-with-search {
    max-width: 300px;

    &.active .select-options-wrap {
      max-width: 300px;
    }

    .select-options {
      max-height: 275px;
    }

    .result-item {
      color: var(--neutral-800);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      transition: all .3s;
      cursor: pointer;
      padding: 4px 0 4px 16px;
    }

    .result-item:hover {
      background: var(--neutral-200);
    }

    .select-options-wrap {
      padding: 8px 0;
      border: none;
    }
  }

  .title {
    font-size: 20px;
    color: var(--neutral-900);
    font-weight: 600;
  }

  .button {
    display: flex;
    justify-content: center;
  }

  .inputs-wrap {
    .inputs {
      overflow-y: scroll;
      max-height: 445px;
    }

    .input-wrap {
      margin-bottom: 20px;
    }

    label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-weight: 600;

      .counter {
        font-size: 14px;
        font-weight: 400;
        color: var(--neutral-600);

        &.full {
          color: #3F825E;
        }
      }
    }
  }
}
</style>
